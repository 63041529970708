// import tokens from './tokens'
// import farms from './farms'
import { Ifo } from './types'

// const wagyuVlxLpToken: Token = {
//   symbol: farms[1].lpSymbol,
//   address: farms[1].lpAddresses,
//   decimals: 18,
// }

const ifos: Ifo[] = [
]

export default ifos
